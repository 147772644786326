<template>
  <v-dialog persistent v-model="open" width="460" attach="#main-container">
    <v-card class="br-6 pb-8">
      <v-card-text class="py-4">
        <v-row>
          <v-col class="d-flex w-100 justify-space-between align-center">
            <div>
              <span class="h5 semiBold w-100"
                >¡Solicitud de descuento aprobada!</span
              >
            </div>
            <v-btn icon @click="toggle" small class="ml-3">
              <Icon name="close" size="15px" />
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div class="d-flex">
              <Icon name="check_circle" size="30px" class="icon-success" />
              <span class="p ml-4"
                >Su solicitud de
                <b>{{ item.monto_descuento }}% de descuento</b> para el plan
                <b>{{ item.plan }}</b> ha sido aprobada exitosamente-</span
              >
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <div class="d-flex flex-column justify-center align-center">
              <v-btn
                rounded
                outlined
                color="primary"
                width="215"
                class="text-none"
                @click="toggle"
                >Entendido</v-btn
              >
              <v-btn
                rounded
                @click="rejected"
                color="primary"
                width="215"
                class="text-none mt-4"
                >Retomar contratación</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
// import inputText from "@/components/Inputs/Input";
import Icon from "@/components/Icon/Icon.vue";

export default {
  props: ["toggle", "item"],
  data() {
    return {
      open: true,
    };
  },
  mounted: {},

  components: {
    Icon,
  },

  methods: {},
};
</script>
<style scoped>
@import "../../assets/CSS/ServiceStyle.css";

.bg-title {
  background-color: #e3eeff !important;
}
.border-table {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}
</style>
