<template>
  <div class="text-center">
    <v-dialog
      v-model="open"
      width="880px"
      height="327px"
      attach="#main-container"
    >
      <v-card class="global-modalCard3">
        <v-layout fill-height class="pa-0">
          <v-flex class="global-span1 text-left mt-8 pt-4 ml-10 pl-6">
            <div v-if="isAuthorized">
              <h4 class="mt-8 titleStyle">¡Descuento autorizado!</h4>
              <h3 class="mt-6 subtitleStyle">
                Autorizaste con éxito el descuento al usuario <br />
                con el
                <strong class="global-strong" :style="{ color: titleColor }"
                  >RUT {{ dataUser.rut | rutFormat }}</strong
                >
                de
                <strong class="global-strong" :style="{ color: titleColor }">{{
                  dataUser.usuario_nombre
                }}</strong
                ><br />
                ¡Felicitaciones! <br />
              </h3>
            </div>

            <div v-if="!isAuthorized">
              <h4 class="mt-8 titleStyle">¡El descuento ha sido rechazado!</h4>
              <h3 class="mt-6 subtitleStyle">
                La solicitud de descuento del
                <strong class="global-strong" :style="{ color: titleColor }"
                  >{{ dataUser.descuento_porcentaje }}%</strong
                >
                ha sido <br />
                rechazada para el RUT<strong
                  class="global-strong"
                  :style="{ color: titleColor }"
                >
                  {{ dataUser.rut | rutFormat }}
                </strong>
                {{ dataUser.usuario_nombre }}
              </h3>
            </div>
          </v-flex>

          <v-flex class="d-flex align-center justify-center">
            <div
              id="ComputerSvg"
              style="align-items: center; justify-content: center"
            >
              <v-flex class="mb-5 text-end justify-end">
                <v-icon
                  v-on:click.native="CloseFinalModal"
                  class="global-pointer mr-8 text-end"
                  >mdi-close</v-icon
                >
              </v-flex>
              <v-flex xs12 class="mr-8">
                <v-img
                  v-if="isAuthorized"
                  class="global-image4"
                  width="130px"
                  src="@/assets/modalImages/descuento.png"
                />
                <v-img
                  v-if="!isAuthorized"
                  class="global-image4"
                  width="90px"
                  src="@/assets/modalImages/Padlock.png"
                />
                <btnprincipal
                  label="Entendido"
                  v-on:click.native="CloseFinalModal"
                  class="mt-8"
                  width="226"
                  height="45"
                />
              </v-flex>
            </div>

            <v-img
              max-width="450px"
              width="430"
              id="BackgroundSvgs"
              src="@/assets/modalImages/Vector.png"
            />
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import btnprincipal from "@/components/Button/Btn-Principal.vue";
import { mapGetters } from "vuex";
export default {
  data: () => ({
    dialog: true,
  }),
  props: ["open", "isAuthorized", "dataUser"],
  components: {
    btnprincipal,
  },
  methods: {
    CloseFinalModal() {
      this.$emit("CloseFinalModal");
    },
  },
  computed: {
    ...mapGetters("Users", ["getTituloColor"]),
    titleColor() {
      if (this.getTituloColor != null) {
        return this.getTituloColor;
      } else {
        return "#0077c8";
      }
    },
  },
};
</script>

<style scoped>
@import "../../assets/CSS/ServiceStyle.css";

h4 {
  font-family: inter;
  z-index: 10;
}

.titleStyle {
  font-size: 2.083vw !important;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.subtitleStyle {
  font-size: 1.389vw !important;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
#BackgroundSvgs {
  position: absolute;
  top: 0%;
  max-width: 450px;
}
#ComputerSvg {
  z-index: 10;
}
:deep() .v-dialog {
  border-radius: 4px;
  margin: 24px;
  overflow: hidden;
  pointer-events: auto;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 100%;
}

:deep() #textarea {
  font-size: 18px;
  color: #050c42 !important;
}

:deep().v-input:hover .v-label {
  color: #050c42 !important;
}
:deep().v-text-field:hover fieldset,
.v-text-field.v-input--has-state fieldset {
  border: 1px solid #0077c8;
}
:deep()
  .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
  > .v-input__control
  > .v-input__slot,
.v-text-field.v-text-field--enclosed .v-text-field__details {
  padding: 10px 0 0 19px;
}
</style>
