<template name="paginationComponent">
  <div class="d-flex justify-center align-center">
    <div class="mr-2">
      <v-btn icon bordered small class="actionButton" @click="buttonPrev()"
        ><Icon name="arrow_back" size="15px"
      /></v-btn>
    </div>
    <div class="d-flex justify-center">
      <v-btn
        small
        icon
        class="mx-1"
        :class="currentPage === 1 ? 'primary--text bold' : ''"
        @click="handlePageClicked(1)"
      >
        {{ 1 }}
      </v-btn>
      <div class="mx-2" v-if="!truncateLeft">...</div>
      <v-btn
        v-for="n in middlePages"
        :key="n"
        small
        icon
        class="mx-1"
        :class="currentPage === n ? 'primary--text bold' : ''"
        @click="handlePageClicked(n)"
      >
        {{ n }}
      </v-btn>
      <div class="mx-2" v-if="!truncateRight">...</div>

      <v-btn
        small
        icon
        class="mx-1"
        v-if="localPages > 1"
        @click="handlePageClicked(localPages)"
        :class="currentPage === localPages ? 'primary--text bold' : ''"
      >
        {{ localPages }}
      </v-btn>
    </div>

    <div class="ml-2">
      <v-btn icon bordered small class="actionButton" @click="buttonNext()">
        <Icon name="arrow_forward" size="15px" />
      </v-btn>
    </div>
  </div>
</template>
<script>
import Icon from "@/components/Icon/Icon.vue";

export default {
  name: "paginationComponent",

  props: ["pages", "changePage", "currentPage"],
  components: { Icon },
  data() {
    return {
      middlePages: [],
      localPages: JSON.parse(this.pages),
    };
  },
  mounted() {
    if (this.localPages === null) {
      this.localPages = 1;
    }
    this.getMiddlePages();
  },
  computed: {
    truncateRight() {
      if (this.localPages <= 7) {
        return true;
      }
      return this.currentPage + 3 >= this.localPages;
    },
    truncateLeft() {
      return this.currentPage - 3 <= 1;
    },
  },

  watch: {
    currentPage() {
      this.getMiddlePages();
    },
    pages() {
      this.localPages = this.pages;
      this.getMiddlePages();
    },
  },
  methods: {
    buttonNext() {
      if (this.currentPage != this.localPages && this.localPages > 1) {
        this.handlePageClicked(this.currentPage + 1);
      }
    },
    buttonPrev() {
      if (this.currentPage > 1 && this.localPages > 1) {
        this.handlePageClicked(this.currentPage - 1);
      }
    },
    handlePageClicked(page) {
      this.changePage(page);
    },
    getMiddlePages() {
      const middle = [];
      for (let i = 0; i < this.localPages; i++) {
        middle.push(i + 1);
      }
      middle.shift();
      middle.pop();
      middle.splice(0, this.currentPage - 4);
      if (middle.length > 5) {
        middle.splice(-(middle.length - 5));
      }
      this.middlePages = middle;
    },
  },
};
</script>
<style scoped>
.actionButton {
  /* background-color: red !important; */
  border: solid 0.1em #cac4d0;
  color: #cac4d0 !important;
  transition: all ease 0.4s;
}
.actionButton:hover {
  border: solid 0.1em #0177c8;
  color: #0177c8 !important;
}

.actionButton span.material-symbols-rounded {
  color: #cac4d0 !important;
  transition: all ease 0.4s;
}
.actionButton:hover span.material-symbols-rounded {
  color: #0177c8 !important;
}
</style>
