<template>
  <v-dialog persistent v-model="open" width="950" attach="#main-container">
    <v-card class="br-6 light">
      <v-card-text>
        <v-row class="pl-10 pt-10">
          <v-col class="d-flex w-100 justify-space-between align-center">
            <div v-if="type === 'REC'">
              <span class="h5 semiBold w-100">Solicitud de descuento</span>
              <v-chip color="#F25C53" class="px-8 ml-10 text-white" small>
                Rechazado
              </v-chip>
            </div>
            <div v-if="type === 'A'">
              <span class="h5 semiBold w-100">Solicitud de descuento</span>
              <v-chip color="#06844D" class="px-8 ml-10 text-white" small>
                Aprobado
              </v-chip>
            </div>
            <div v-if="type === 'PEN'">
              <span class="h5 semiBold w-100">Solicitud de descuento</span>
              <v-chip color="#FAB76A" class="px-8 ml-10" small>
                Pendiente
              </v-chip>
            </div>
            <v-btn icon @click="toggle" small class="ml-3">
              <Icon name="close" size="15px" />
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="px-10">
          <v-col cols="12">
            <div v-if="type === 'REC'">
              <span class="p">Motivo del rechazo:</span>
              <v-textarea
                rows="4"
                outlined
                auto-grow
                row-height="20"
                :disabled="true"
                v-model="motivo"
              ></v-textarea>
            </div>
            <span class="p semiBold title-color">Resumen de la operación</span>
            <div class="d-flex border-table mt-3">
              <div class="d-flex w-50">
                <div class="d-flex flex-column bg-title px-4 py-3">
                  <span class="p semiBold py-2">Cotización Nº</span>
                  <span class="p semiBold py-2">Rut</span>
                  <span class="p semiBold py-2">Nombre</span>
                </div>
                <div
                  v-if="loadingData"
                  class="d-flex flex-grow-1 flex-column px-4 py-3"
                >
                  <span class="p py-2" v-for="index in 3" :key="index">
                    <v-skeleton-loader type="text"></v-skeleton-loader>
                  </span>
                </div>
                <div v-else class="d-flex flex-column px-4 py-3">
                  <span class="p py-2">{{
                    resume.asegurado.numero_documento | rutFormat
                  }}</span>
                  <span class="p py-2"
                    >{{ resume.asegurado.nombres }}
                    {{ resume.asegurado.primer_apellido }}
                    {{ resume.asegurado.segundo_apellido }}</span
                  >
                </div>
              </div>

              <div
                v-if="loadingData"
                class="d-flex flex-grow-1 flex-column px-4 py-3"
              >
                <span class="p py-2" v-for="index in 3" :key="index">
                  <v-skeleton-loader type="text"></v-skeleton-loader>
                </span>
              </div>
              <div v-else class="d-flex w-50">
                <div class="d-flex flex-column bg-title px-4 py-3">
                  <span class="p semiBold py-2">Plan</span>
                  <span class="p semiBold py-2">Compañía</span>
                  <span class="p semiBold py-2">Cuota </span>
                </div>
                <div class="d-flex flex-column px-4 py-3">
                  <span class="p py-2 ellipsis"
                    >{{ resume.producto.descripcion
                    }}{{ resume.plan.descripcion }}</span
                  >
                  <span class="p py-2" v-if="logo">
                    <v-img :src="logo" heigth="50" width="50"
                  /></span>
                  <span v-if="item?.calculo" class="p py-2">
                    {{ item?.calculo?.monto_seguro_local | moneyFormat }}
                    <span class="px-2">|</span
                    >{{ item?.calculo?.monto_seguro | UFPrice }}</span
                  >
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row class="px-10">
          <v-col cols="12">
            <div class="d-flex border-table mt-3">
              <div class="d-flex w-100">
                <div class="d-flex flex-column bg-title px-4 py-3">
                  <span class="p semiBold py-2">Descuento solicitado</span>
                  <span class="p semiBold py-2"
                    >Valor de cuota en $ con descuento incluido</span
                  >
                  <span class="p semiBold py-2">Cargo descuento</span>
                  <span class="p semiBold py-2">Motivo</span>
                </div>
                <div
                  v-if="loadingData"
                  class="d-flex flex-grow-1 flex-column px-4 py-3"
                >
                  <span class="p py-2" v-for="index in 4" :key="index">
                    <v-skeleton-loader type="text"></v-skeleton-loader>
                  </span>
                </div>
                <div v-else class="d-flex flex-column px-4 py-3">
                  <span
                    class="p py-2"
                    v-if="(this.item.tipo_descuento = 'PORCENTUAL')"
                    >{{ this.item.monto_descuento }}%</span
                  >
                  <span v-if="item.calculo" class="p py-2">{{
                    item.calculo.nuevo_monto_seguro_local | moneyFormat
                  }}</span>
                  <span class="p py-4" v-else>{{ " " }}</span>
                  <span class="p py-2">{{ this.item.cargo_descripcion }}</span>
                  <span class="p py-2">{{ this.item.motivo_descripcion }}</span>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row class="px-10">
          <v-col cols="12">
            <span class="p semiBold title-color">Solicitado por</span>
            <div class="d-flex border-table mt-3">
              <div class="d-flex w-100">
                <div class="d-flex flex-column bg-title px-4 py-3">
                  <span class="p semiBold py-2">Rut</span>
                  <span class="p semiBold py-2">Nombre</span>
                  <span class="p semiBold py-2" v-if="item.concesionario"
                    >Concesionario</span
                  >
                  <span class="p semiBold py-2" v-if="item.sucursal"
                    >Sucursal</span
                  >
                  <span class="p semiBold py-2" v-if="item.convenio"
                    >Convenio</span
                  >
                </div>
                <div
                  v-if="loadingData"
                  class="d-flex flex-grow-1 flex-column px-4 py-3"
                >
                  <span class="p py-2" v-for="index in 5" :key="index">
                    <v-skeleton-loader type="text"></v-skeleton-loader>
                  </span>
                </div>
                <div v-else class="d-flex flex-column px-4 py-3">
                  <span class="p py-2">{{
                    this.resume.asegurado.numero_documento
                  }}</span>
                  <span class="p py-2"
                    >{{ resume.vendedor.vendedor_descripcion }}
                  </span>
                  <span class="p py-2" v-if="item.concesionario">{{
                    item?.concesionario
                  }}</span>
                  <span class="p py-2" v-if="item.sucursal"
                    >{{ item?.sucursal }}
                  </span>
                  <span class="p py-2" v-if="item.convenio"
                    >{{ item?.sucursal }}
                  </span>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="d-flex justify-center" v-if="this.type !== 'PEN'">
              <v-btn
                rounded
                @click="toggle"
                color="primary"
                width="156"
                class="text-none ml-8"
                >Entendido</v-btn
              >
            </div>
            <div v-else class="d-flex justify-center">
              <v-btn
                rounded
                outlined
                color="primary"
                width="156"
                class="text-none"
                @click="reject"
                :loading="loadingReject"
                >Rechazar</v-btn
              >
              <v-btn
                rounded
                @click="approve"
                color="primary"
                width="156"
                class="text-none ml-8"
                >Aprobar</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import Icon from "@/components/Icon/Icon.vue";
import { mapActions } from "vuex";
import axios from "axios";

export default {
  props: ["toggle", "loadingReject", "reject", "approve", "item", "type"],
  data() {
    return {
      open: true,
      motivo: "",
      resume: null,
      loadingData: true,
      logo: "",
    };
  },
  async mounted() {
    if (this.item?.rechazo) {
      this.motivo = this.item.rechazo.motivo;
    }
    this.resume = await this.getResume(this.item.cotizacion);
    this.loadingData = false;
    if (this.resume) {
      this.logo = await this.getLogo();
    }
  },

  components: {
    Icon,
  },

  methods: {
    ...mapActions("Discounts", ["getResume"]),
    async getLogo() {
      return axios.get(this.resume.compania.logo).then((res) => {
        return res.data.data.url;
      });
    },
  },
};
</script>
<style scoped>
@import "../../assets/CSS/ServiceStyle.css";

.bg-title {
  background-color: #e3eeff !important;
}
.border-table {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 80%;
}
</style>
